import React from 'react';
import './DropPH.css';
const DropPH = () => {
    return (
        <div className='drop-ph'>
            <div className='ph-text'></div>
            <div className='ph-text'></div>
            <div className='ph-text'></div>
        </div>
    );
};

export default DropPH;